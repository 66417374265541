import React from 'react';
import { Container, Row, Col} from 'reactstrap';
import GoatsImage from '../../images/home-page-top-blok.png';
import Goatoutline from '../../images/goat-outline.png';
import SheepOutline from '../../images/ships-sale.png'
import Sandler from '../../images/svg-images/sander.svg';
import Hotel from '../../images/svg-images/hotel.svg';
import { Link } from "gatsby";
import './most-popular-servises.css';



const MostPopularServises = () => {
    return (
        <>
        <Container fluid className="directions pt-2">
            <Row>
                <Col xs="12" md="4" className="p-md-4 mb-2">
                    <Link to="/prodazha-plemennyh-zhivotnyh/prodazha-koz">
                      <div className="directions-item text-center p-2">
                          <img src={Goatoutline} className="item-icon font-white m-3" alt="" title="" ></img>
                          <h4 className="item-title">ПРОДАЖА КОЗ</h4>
                      </div>
                    </Link>
                </Col>
                <Col xs="12" md="4" className="p-md-4 mb-2" >
                    <a href="#how-ordered" >
                      <div className=" text-center p-2" style={{backgroundImage: `url(${GoatsImage})`}}> 
                      <div className="directions-item-white" >
                      <span className="separator-fullScreen-white"></span>
                          <h4 className="font-white item-title">КАК ЗАКАЗАТЬ ЖИВОТНЫХ<br />в PETERSDORF</h4>
                          </div>
                      </div>
                    </a>
                  
                </Col>
                <Col xs="12" md="4" className="p-md-4 mb-2">
                  <Link to="/prodazha-plemennyh-zhivotnyh/prodazha-ovets">
                    <div className="directions-item text-center p-2"> 
                        <img src={SheepOutline} className="item-icon font-white m-3" alt="" title="" ></img>
                        <h4 className="item-title">ПРОДАЖА ОВЕЦ</h4>
                    </div>
                  </Link>
                </Col>
            </Row> 
        </Container>
        </>
    )
}
export default MostPopularServises;