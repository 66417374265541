import React, {Component} from 'react';
import { UncontrolledCarousel, Row, Col } from 'reactstrap';
import MobileBunner from '../images/mobile-bunner.jpg'
import LogoPetersdorf from '../images/Petersdorf.svg';




export default class Slider extends Component {
  constructor(props) {
      super(props);
      this.state = { width: 0 };
      this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
}

  updateWindowDimensions() {
      this.setState({ width: document.documentElement.clientWidth });
    }

render() {
  const {items} = this.props;
  const {width} = this.state;
  let content = <UncontrolledCarousel key={items.key} items={items} />

  if (width < 475) {
    content = (
      <div className="w-100 text-center mobile-bunner-slider" style={{backgroundImage:`url(${MobileBunner})`}}>
          <div className="">
            <img className="w-50" src={LogoPetersdorf} />
            <p className="h1">Племенной центр Petersdorf</p>
            <p className="h5">Продажа племенных коз и овец из Европы</p>
          </div>
      </div>
    )
  }

  return (
    <>
    {content}
  </>
  )
}
}