import React, {Fragment} from 'react';
import { Container, Row, Col} from 'reactstrap';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Slider from '../components/slider-gelery'
import OfficeAtMap from '../components/office-on-map/office-on-map'
import MostPopularServises from '../components/most-popular-servises/most-popular-servises';
import FarmPetersdorf from '../images/petersdorf-farm.jpg';
import Advantages from '../components/advantages/advantages';
import FormQuickOrder from '../components/form-quick-oreder/form-quick-order';
import Picking from '../components/picking/picking'
import Bunner1 from '../images/top-background-main-page.jpg'
import Bunner2 from '../images/slide-goat-sale.jpg'
import Bunner3 from '../images/slide-goat-sale-1.jpg'
import Bunner4 from '../images/slide-goat-sale-2.jpg'


export default class Index extends React.Component {
  
  render() {

    const items = [
      {
        src: Bunner1,
        altText: 'Племенной центр Petersdorf',
        caption: 'Продажа племенных коз и овец из Европы',
        header: 'Племенной центр Petersdorf',
        key: '1'
      },
      {
        src: Bunner2,
        altText: 'Животные из Европпы под заказ',
        caption: 'Привозим коз и овец с высокой племенной ценностью в Россию',
        header: 'Животные из Европпы под заказ',
        key: '2'
      },
      {
        src: Bunner4,
        altText: 'Племенной центр Petersdorf',
        caption: 'Животные проходят карантин на нашей ферме в Калининграде',
        header: 'Собственный карантийный центр',
        key: '3'
      },
      {
        src: Bunner3,
        altText: 'Племенной центр Petersdorf',
        caption: 'Племенной центр берет оформление всех документов на себя',
        header: 'Без бюрократии',
        key: '4'
      },
      
    ];

   
    return (
        <Fragment>
           <SEO  title="Племенной центр Petersdorf | Поставка коз и овец из Европы"
                description='Племенной центр Петерсдорф специализируется на поставках коз и овец разных пород из Европейских животноводств. 
                Оформление их на территории России. Мы соединяем множество ферм и животноводсятв мира. Взаимодействуем с племенными центрами.'
                >           
          </SEO>
           
            <Layout>
            <Slider items={items}/>
        <MostPopularServises />
       
        <Container fluid>
          <span className="separator-fullScreen mt-5 mb-5 d-none d-md-block"></span>
        <Row className="mt-5">
              <Col md="6" xs='12' className="text-center">
                <h1>Племенной центр "Петерсдорф"</h1>
                <p className="text-justify">
                  Племенной центр "Petersdorf" занимается продажей коз и овец элитных пород в России.
                  Мы работаем только с лучшими Европейскими животноводческими фермами, которые поставляют нам породистых,
                  чистых животных разных пород с отличными родословными.
                  У нас вы можете заказать коз и овец любых пород и мы доставим их в Россию вкратчайше сроки. 
                  При покупке коз и овец из Европы и поставке их в РФ, Вам не нужно получать разрешение на ввоз животных 
                  в Минсельхозе и Россельхознадзоре, племенной центр берет оформление всех документов на себя. 
                  Вы только приезжаете на наши карантийные фермы и забираете своих любимцев. 
                  Европейские ветеринарные сертификаты и обследование на карантине российскими 
                  ветврачами гарантируют чистоту животных.
                </p>
              </Col>
              <Col md="6" xs='12' className="text-center">
                <img src={FarmPetersdorf} className="w-100" alt="Племенной центр Петерсдорф" title="Племенной центр Петерсдорф" />
              </Col>
            </Row>
        </Container>
        <Advantages />
        <FormQuickOrder />
        <Picking />
        <OfficeAtMap />
      </Layout>
    </Fragment>
    )
  }
}



