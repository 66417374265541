import React, {Component} from 'react';
import {Link} from 'gatsby';
import { Container, Row, Col, Form, FormGroup, Input, FormText } from 'reactstrap';



export default class FormQiuckOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
          message: {
            name: '',
            phone: '',
            email: '',
            message: '',
            respServer: {
              success: undefined,
              error: ''
            },
          },
          width: ""
        }
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangeMessage = this.onChangeMessage.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
    }

    componentDidMount() {
      this.updateWindowDimensions();
  }
  updateWindowDimensions() {
      this.setState({ width: document.documentElement.clientWidth });
    }


    onChangeName(event) {
      const name = event.target.value;
      this.setState(
        state => ({
          message: {
            ...state.message,
            name:name
          }
        })
      )
    }

    onChangePhone(event) {
        const phone = event.target.value;
        this.setState(
          state => ({
            message: {
              ...state.message,
              phone: phone
            }
          })
        )
      }

      onChangeEmail(event) {
        const email = event.target.value;
        this.setState(
          state => ({
            message: {
              ...state.message,
              email:email
            }
          })
        )
      }

      onChangeMessage(event) {
        const message = event.target.value;
        this.setState(
          state => ({
            message: {
              ...state.message,
              message:message
            }
          })
        )
      }

    formSubmit(event) {
        event.preventDefault();
        let newMessage = this.state.message;
        fetch(`http://petersdorf.ru/submit-one-input.php`, {
          method:"POST",
          body: JSON.stringify(newMessage),
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json"
          })
        }).then(response => {
          response.json().then(data => {
            this.setState(state => ({
              message:{
              ...state.message,
              respServer: {
                ...state.message.respServer,
                success: data.success
              }
            }}))
          })
        })
      }
   
    render() {
      const {width} = this.state;
      let textOnButton = 'ОТПРАВИТЬ';
      let btnDisable = false;
      let btnStyle  = 'button-solt mt-3'
          if (this.state.message.respServer.success === true) {
            textOnButton = 'Заявка принята!';
            btnStyle = 'button-salt-disable mt-3'
            btnDisable = true;
          }
        
      if(width > 450) {
        return (
            <Form onSubmit={this.formSubmit} style={{textAlign: "center"}} className="pb-3 pt-3 m-3"> 
              <Row>
                <Col md="6">
                    <label className="text-left" htmlFor="nameInput">Имя</label>
                    <Input onChange={this.onChangeName} style={{"height":"6vh"}} className="input-field" type="text" name="nameInput" id="name" placeholder="Имя" minLength="3" maxLength="30" />
                    <label className="text-left" htmlFor="phoneInput">Телефон</label>
                    <Input onChange={this.onChangePhone} style={{"height":"6vh"}} className="input-field" type="tel" name="phoneInput" id="phone" placeholder="Телефон" minLength="5" maxLength="16" />
                    <label className="text-left" htmlFor="emailInput">Email</label>
                    <Input onChange={this.onChangeEmail} style={{"height":"6vh"}} className="input-field" type="email" name="emailInput" id="email" placeholder="Email" pattern="([A-z0-9_.-]{1,})@([A-z0-9_.-]{1,}).([A-z]{2,8})" minLength="0" maxLength="25" />
                    <button className={btnStyle} disabled={btnDisable}>{textOnButton}</button>
                </Col>
                <Col md="6">
                    <label className="text-left" htmlFor="nameInput">Напишите нам</label>
                    <Input onChange={this.onChangeMessage} style={{"height":"36vh"}} className="" type="textarea" name="text" id="message" className="input-field"/>
                    <FormText style={{"height":"8vh"}} className="font-white" rows="10" cols="45"  name="text" id="message">
                      Нажимая на кнопку вы соглащаетесь с <Link to="/politika-konfidencialnosti" className="font-green">политикой конфиденциальности</Link>
                    </FormText>
                </Col>
              </Row>
            </Form>
                  
      );
    } else {
      return (
        <Form onSubmit={this.formSubmit} style={{textAlign: "center"}} className="pb-3 pt-3 m-3"> 
          <Row>
            <Col md="6">
                <label className="text-left" htmlFor="nameInput">Имя</label>
                <Input onChange={this.onChangeName} style={{"height":"6vh"}} className="input-field" type="text" name="nameInput" id="name" placeholder="Имя" minLength="3" maxLength="30" />
                <label className="text-left" htmlFor="phoneInput">Телефон</label>
                <Input onChange={this.onChangePhone} style={{"height":"6vh"}} className="input-field" type="tel" name="phoneInput" id="phone" placeholder="Телефон" pattern="^[ 0-9]+$" minLength="5" maxLength="16" />
                <label className="text-left" htmlFor="emailInput">Email</label>
                <Input onChange={this.onChangeEmail} style={{"height":"6vh"}} className="input-field" type="email" name="emailInput" id="email" placeholder="Email" pattern="([A-z0-9_.-]{1,})@([A-z0-9_.-]{1,}).([A-z]{2,8})" minLength="0" maxLength="25" />
            </Col>
            <Col md="6">
                <label className="text-left" htmlFor="nameInput">Напишите нам</label>
                <Input onChange={this.onChangeMessage} style={{"height":"36vh"}} className="" type="textarea" name="text" id="message" className="input-field"/>
                <button className="button-solt mt-3" disabled={btnDisable}>{textOnButton}</button>
                <FormText style={{"height":"8vh"}} className="font-white" rows="10" cols="45"  name="text" id="message">
                  Нажимая на кнопку вы соглащаетесь с <Link to="/politika-konfidencialnosti" className="font-green">политикой конфиденциальности</Link>
                </FormText>
            </Col>
          </Row>
        </Form>
      )
    }
}
}
